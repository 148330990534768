import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function FranchisePage() {
const colorBg = {
color:'#ffc907',
}
const [modalShow, setModalShow] = React.useState(false);
return (
<div >
   <div id='Products'>
      <div className='container'>
         <h2 className="text-center head_h2">Join the Misty Moo Franchise Family</h2>
         <h2 className="text-center mb-5 head_h4">A Turnkey Dairy Delight Experience</h2>
         <div className='row'>
            <div class="col-lg-6">
               <div class="post-item">
                  <div class="post-inner">
                     <div class="post-content">
                        <img class="post-thumb  mb-lg-3" src="../images/fi_9204229.svg" alt="Misty Moo Milk Store" />
                        <h4>Your Opportunity to Shine in the Dairy Industry</h4>
                        <p>Misty Moo offers an exciting franchise opportunity for passionate entrepreneurs who dream of making a mark in the dairy industry. Our franchise model is designed to provide you with a comprehensive, turnkey solution, ensuring you have everything you need to start and run your Misty Moo store successfully.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="post-item">
                  <div class="post-inner">
                     <div class="post-content">
                        <img class="post-thumb  mb-lg-3" src="../images/fi_2211068.svg" alt="Misty Moo Milk Store" />
                        <h4>Store Setup and Design</h4>
                        <p>Aesthetically pleasing and functional store design, reflecting Misty Moo’s brand ethos. Assistance in selecting the ideal location to maximise foot traffic and visibility.Guidance on interior layout, including specifications for furniture, fixtures, and equipment to optimise customer experience and operational efficiency.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="post-item">
                  <div class="post-inner">
                     <div class="post-content">
                        <img class="post-thumb  mb-lg-3" src="../images/fi_5095453.svg" alt="Misty Moo Milk Store" />
                        <h4>Initial Product Offering</h4>
                        <p>A starter pack of our range of delectable dairy products, including our famed ghee, paneer, flavoured milks, and more. Regular updates and additions to the product lineup to keep the offerings fresh and exciting.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="post-item">
                  <div class="post-inner">
                     <div class="post-content">
                        <img class="post-thumb  mb-lg-3" src="../images/fi_2211103.svg" alt="Misty Moo Milk Store" />
                        <h4>Branding and Marketing</h4>
                        <p>Access to Misty Moo’s strong brand identity and proven marketing strategies.Initial marketing support including store launch promotions, local advertising templates, and digital marketing campaigns.Ongoing marketing guidance to drive customer engagement and loyalty.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="post-item">
                  <div class="post-inner">
                     <div class="post-content">
                        <img class="post-thumb  mb-lg-3" src="../images/fi_6956775.svg" alt="Misty Moo Milk Store" />
                        <h4>Comprehensive Training</h4>
                        <p>Extensive training program covering product knowledge, customer service, store management, and operational best practices. Regular refresher courses and updates on new products and processes.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="post-item">
                  <div class="post-inner">
                     <div class="post-content">
                        <img class="post-thumb  mb-lg-3" src="../images/fi_8193998.svg" alt="Misty Moo Milk Store" />
                        <h4>Advanced Software and Technology</h4>
                        <p>State-of-the-art Point of Sale (POS) and inventory management system to streamline operations. Access to our customer relationship management (CRM) tools for enhanced customer engagement and retention.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="post-item">
                  <div class="post-inner">
                     <div class="post-content">
                        <img class="post-thumb  mb-lg-3" src="../images/fi_5095165.svg" alt="Misty Moo Milk Store" />
                        <h4>Continued Support and Development</h4>
                        <p>Ongoing operational support from the Misty Moo head office. Regular quality and performance audits to ensure adherence to our high standards. Continuous research and development to stay ahead in the market.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="post-item header-top text-center">
                  <div class="post-inner banner-title">
                     <div class="post-content">
                        <h4 style={colorBg}>Misty Moo</h4>
                        <>
                        <Button variant="primary" onClick={() => setModalShow(true)}>
                        Join now
                        </Button>
                        <MyVerticallyCenteredModal
                           show={modalShow}
                           onHide={() =>
                        setModalShow(false)}
                        />
                        </>
                        <p className='text-white mt-3'>Joining the Misty Moo family means embarking on a journey with a brand that is committed to quality, innovation, and customer satisfaction. We are looking for partners who share our passion for delivering dairy delights and are eager to grow with us. If you’re ready to make a splash in the dairy world, Misty Moo is your ideal franchise choice.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
)
}
export default FranchisePage
function MyVerticallyCenteredModal(props) {
return (
<Modal
   {...props}
   size="lg"
   aria-labelledby="contained-modal-title-vcenter"
   centered className='pop-up'
   >
   <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter" className='head_h4_text'>
         Send Message us
      </Modal.Title>
   </Modal.Header>
   <Modal.Body className='p-5 banner-title'>
      <form>
         <div class="row">
            <div class="col-md-6">
               <div class="form-group">
                  <input type="text" class="form-control den-login-form-control mb-4" id="recipient-name" placeholder="Enter Your Name" />
               </div>
            </div>
            <div class="col-md-6">
               <div class="form-group">
                  <input type="text" class="form-control den-login-form-control mb-4" id="recipient-name" placeholder="Enter Your Email" />
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-6">
               <div class="form-group">
                  <input type="text" class="form-control den-login-form-control mb-4" id="recipient-name" placeholder="Enter Your Phone" />
               </div>
            </div>
            <div class="col-md-6">
               <div class="form-group">
                  <input type="text" class="form-control den-login-form-control mb-4" id="recipient-name" placeholder="Enter Your Location" />
               </div>
            </div>
         </div>
         <div class="form-group">
            <textarea rows="7" class="form-control den-login-form-control height mb-4" id="message-text" placeholder="Message..."></textarea>
         </div>
         <input class="btn mb-3" type="submit" value="Submit Now" />
      </form>
   </Modal.Body>
</Modal>
);
}
