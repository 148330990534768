import React from "react";

import Footer from "./Footer";
function TermsPrivacyShipping() {
  return (
    <div>
      <div className="pt-5 padding-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 px-lg-5 shadow">
              <h3 className="text-center mb-5 head_h3">Privacy Policy</h3>
              <h4 class="head_h4">Introduction:</h4>
              <p className="mb-4">
                Welcome to Misty Moo Milk Store. We are committed to protecting
                your privacy. This policy details the types of personal
                information we collect, how it is used, and how we keep it safe.
              </p>
              <h4 class="head_h4">Information Collection:</h4>
              <p className="mb-4">
                We collect information through website interactions, order
                placements, and customer accounts. This may include name,
                contact details, and purchase history.
              </p>
              <h4 class="head_h4">Use of Information:</h4>
              <p className="mb-4">
                Your information helps us personalize your experience, improve
                customer service, and process transactions efficiently.
              </p>
              <h4 class="head_h4">Protection of Information:</h4>
              <p className="mb-4">
                We implement a variety of security measures to maintain the
                safety of your personal information.
              </p>
              <h4 class="head_h4">Third-party Disclosure:</h4>
              <p className="mb-4">
                We do not sell, trade, or otherwise transfer your personally
                identifiable information to outside parties without your
                consent, except as necessary to fulfill your requests, such as
                to ship an order.
              </p>
              <h3 className="text-center my-5 head_h3">Terms and Conditions</h3>
              <h4 class="head_h4">Overview:</h4>
              <p className="mb-4">
                These terms govern the use of Misty Moo Milk Store’s website and
                the purchase of any products from it.
              </p>
              <h4 class="head_h4">Product Purchases:</h4>
              <p className="mb-4">
                All purchases made through our website are subject to product
                availability. We may, at our sole discretion, limit or cancel
                the quantities offered on our website or limit the sales of our
                products to any person, household, geographic region, or
                jurisdiction.
              </p>
              <h4 class="head_h4">Accuracy of Information:</h4>
              <p className="mb-4">
                We strive to ensure that all details, descriptions, and prices
                of products are accurate, but errors may occur. If we discover
                an error in the price of any products that you have ordered, we
                will inform you of this as soon as possible.
              </p>
              <h4 class="head_h4">User Obligations:</h4>
              <p className="mb-4">
                You agree to provide current, complete, and accurate purchase
                and account information for all purchases made at our store.
              </p>
              <h3 className="text-center my-5 head_h3">
                Cancellation and Refund Policy
              </h3>
              <h4 class="head_h4">Cancellations:</h4>
              <p className="mb-4">
                You may cancel your order within 24 hours of purchase. Please
                contact our customer service team to initiate a cancellation.
              </p>
              <h4 class="head_h4">Refunds:</h4>
              <p className="mb-4">
                Refunds are processed based on the payment method used at the
                time of purchase. Please allow up to 7-10 business days for the
                refund amount to reflect in your account.
              </p>
              <h4 class="head_h4">Non-returnable Items:</h4>
              <p className="mb-4">
                Due to the nature of our products, opened or tampered products
                cannot be returned.
              </p>
              <h3 className="text-center my-5 head_h3">
                Shipping and Delivery
              </h3>
              <h4 class="head_h4">Shipping Charges:</h4>
              <p className="mb-4">
                Shipping charges for your order will be calculated and displayed
                at checkout.
              </p>
              <h4 class="head_h4">Delivery Estimates:</h4>
              <p className="mb-4">
                We strive to ship our products within 2-3 business days.
                Estimated delivery times are to be used as a guide only and
                commence from the date of dispatch.
              </p>
              <h4 class="head_h4">International Shipping:</h4>
              <p className="mb-4">
                Currently, we only ship to addresses within India.
              </p>
              <h4 class="head_h4">Shipping Restrictions:</h4>
              <p className="mb-5">
                Deliveries are made to physical addresses only. We do not ship
                to P.O. box addresses.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default TermsPrivacyShipping;
