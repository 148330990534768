import React from 'react';
import { Link } from 'react-router-dom'
function InstaVideos() {
const myStyle = {
width: "245px", // Corrected: 'with' to 'width'
};
return (
<div>
   <div className="bg-image-6 padding-top padding-bottom">
      <div className='container'>
         <h2 className="text-center mb-5 head_h2">Misty Moo Happy Reels</h2>
         <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 text-center'>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-1.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-1.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-3.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-3.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-4.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-4.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-5.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-5.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-pop-1.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-pop-1.mp4" type="video/ogg"/>
               </video>
            </div>
         </div>
      </div>
   </div>
   <div className='container padding-top banner-title padding-bottom'>
      <div className='row align-items-center'>
         <div className='col-md-6 col-lg-6'>
            <img src="../images/imag-gee.png" class="img-fluid" alt="Misty Moo Milk"/>
         </div>
         <div className='col-md-6 col-lg-6'>
            <h2 className="head_h2">Who We Are</h2>
            <p>Misty Moo represents a journey of passion, quality, and commitment in the dairy industry. Founded on the principles of purity and freshness, our story began with a simple aim - to bring the finest dairy products directly from our farms to your homes.</p>
            <p>Our range of products, from the creamiest paneer to the most aromatic flavored milks, reflects our dedication to quality. We believe in the power of natural, wholesome ingredients, and this belief is the cornerstone of everything we do.</p>
            <p>At Misty Moo, we're more than just a brand; we're a family. We work closely with local farmers, embracing community-driven methods to bring you dairy products that are not only delicious but also sustainably produced. Join us on this delightful journey and experience the Misty Moo difference – where every product tells a story of love, care, and the joy of dairy.</p>
            <p className='pt-lg-4'>
               <Link class="btn delay4" to={"./Franchise"}>Franchise Opportunity</Link> <a href="https://9d4236-4c.myshopify.com/products/cow-ghee-500-ml-organic-cow-ghee-misty-moo-milk-store" target="_blank" class="btn-2 delay4">Order Ghee Online</a>
            </p>
         </div>
      </div>
   </div>
</div>
);
}
export default InstaVideos;
