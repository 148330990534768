import React from 'react'
import {Link} from "react-router-dom";
import {Container, Nav , Navbar} from 'react-bootstrap';
function NavMenu() {
   const myStyle = {
      padding: '12px 52px',
      borderRadius: '50px',
      textAlign: 'center',
      fontSize: '15px',
      color: '#fff',
      textTransform: 'capitalize',
      transition: 'all 0.3s ease',
      fontFamily: '"Roboto", sans-serif',
      fontWeight: 700,
      border: '1px solid #FFC907',
      backgroundColor: '#FFC907',
      };
return (
<div className='top_menus'>
   <div class="header-top">
      <div class="container">
         <div class="htop-area">
            <div class="htop-left">
               <ul class="htop-information">
                  <li><i class="far fa-envelope pe-2"></i> info@mistymoo.in</li>
                  <li><i class="fas fa-phone-volume pe-2"></i> +91 89256 74441</li>
                  <li><i class="fas fa-map-marker-alt pe-2"></i> 4/205 Illango Street, Colony Iyer Bunglow, Madurai.</li>
               </ul>
            </div>
            <div class="htop-right">
               <ul>
                  <li><a target="_blank" href="https://www.instagram.com/mistymoomilkstore/"><i class="fab fa-instagram pe-2"></i> @mistymoomilkstore</a></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
<div className='top_men'>
<Navbar expand="lg" className="navbar-light ">
      <Container>
         <Navbar.Brand><Link to="/"><img src="../mistymoo-milk.svg" alt="Misty Moo Milk"/></Link></Navbar.Brand>
         <Navbar.Toggle aria-controls="basic-navbar-nav"/>
         <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end' >
            <Nav className="justify-content-end main-menu align-items-center banner-title">
               <Nav.Link><Link active to="/home">Home</Link></Nav.Link>
               <Nav.Link href="#AboutUs">About Us</Nav.Link>
               <Nav.Link href="#Products">Products</Nav.Link>
               <Nav.Link href="#Services">Services</Nav.Link>
               <Nav.Link href="#Gallery">Gallery</Nav.Link>
               <Nav.Link><Link to="./Franchise">Franchise</Link></Nav.Link>
               <Nav.Link href="#Contact_Us">Contact Us</Nav.Link>
               <Nav.Link target="_blank" style={myStyle} href="https://9d4236-4c.myshopify.com/products/cow-ghee-500-ml-organic-cow-ghee-misty-moo-milk-store">Order Ghee</Nav.Link>

               
               {/* <Nav.Link><Link to="./terms-privacy-shipping">Privacy Policy</Link></Nav.Link> */}
            </Nav>
         </Navbar.Collapse>
      </Container>
   </Navbar>
</div>
</div>
)
}
export default NavMenu
