import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div id="Contact_Us">
      <div className="footer-top padding-top padding-bottom banner-title">
        <div className="container">
          <div className="row padding-top">
            <div className="col-md-4">
              <h3 className="head_h3 mb-4">About Us</h3>
              <p>
                <a href="#" class="footer-logo">
                  <img
                    src="../mistymoo-milk.svg"
                    class="img-fluid footer-logo"
                    alt="Misty Moo Milk"
                  />
                </a>
                <p>
                  At Misty Moo, we're dedicated to bringing you the freshest,
                  purest, and most delicious dairy products.
                </p>
                <ul class="footer-location">
                  <li>
                    <div class="icon-part">
                      <i class="fas fa-phone-volume"></i>
                    </div>
                    <div class="content-part">
                      <p>+91 89256 74441</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon-part">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="content-part">
                      <p>info@mistymoo.in</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon-part">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="content-part">
                      <p>
                        4/205 Illango Street Colony Iyer Bunglow, <br></br>{" "}
                        Madurai, India 625014.
                      </p>
                    </div>
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-4">
              <h3 className=" head_h3 mb-4">Top Selling Product</h3>
              <p>
                <ul class="footer-post">
                  <li>
                    <div class="post-thumb">
                      <a href="#">
                        <img
                          width="110"
                          src="../images/Butter.png"
                          alt="Misty Moo Milk"
                        />
                      </a>
                    </div>
                    <div class="post-content">
                      <h5>
                        <a href="#">Butter</a>
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div class="post-thumb">
                      <a href="#">
                        <img
                          width="110"
                          src="../images/Panner.png"
                          alt="Misty Moo Milk"
                        />
                      </a>
                    </div>
                    <div class="post-content">
                      <h5>
                        <a href="#">Paneer</a>
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div class="post-thumb">
                      <a href="#">
                        <img
                          width="110"
                          src="../images/Nuts.png"
                          alt="Misty Moo Milk"
                        />
                      </a>
                    </div>
                    <div class="post-content">
                      <h5>
                        <a href="#">Nuts</a>
                      </h5>
                    </div>
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-4">
              <h3 className=" head_h3 mb-4">Send Message us</h3>
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control den-login-form-control mb-3"
                      id="recipient-name"
                      placeholder="Your Name"
                    />
                    <input
                      type="text"
                      class="form-control den-login-form-control mb-3"
                      id="recipient-name"
                      placeholder="Phone No"
                    />
                    <input
                      type="text"
                      class="form-control den-login-form-control mb-3"
                      id="recipient-name"
                      placeholder="Your Location"
                    />
                    <textarea
                      rows="7"
                      class="form-control den-login-form-control height mb-3"
                      id="message-text"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <input class="btn" type="submit" value="Submit Now" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="section-wrapper">
            <div className="row">
              <div className="col-md-4">
                <p class="text-center">
                  <Link
                    to="/terms-privacy-shipping"
                    style={{ color: "#01552c" }}
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
              <div className="col-md-8">
                <p >
                  © 2024 Misty Moo Milk Stores Private Limited.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
