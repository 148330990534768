import React from 'react'
function Gallery() {
return (
<div>
{/* 
<div className="bg-image-1 padding-top padding-bottom">
   */}
   <div className="pt-lg-5 padding-bottom banner-title" id='Gallery'>
      <section class="newslater-area  pt-120 pb-200 gallery-video">
         <div class="container">
            <div class="row align-items-center">
               <div class="col-xl-7 col-lg-7">
                  <div class="section-title newslater-title">
                     <div class="text">
                        <h5> Misty Moo</h5>
                        <h2>Join Us &amp; Get Special Offers From Us</h2>
                     </div>
                  </div>
               </div>
               <div class="col-xl-5 col-lg-5">
                  <form id="contact-form4" action="#" method="post" class="contact-form newslater " >
                     <div class="form-group p-relative">
                        <input class="form-control" id="email2" name="email" type="email" placeholder="Email Address..." value="" required="" /> 
                        <button type="submit" class="btn btn-custom" id="send2">Subscribe Now</button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </section>
      <section id="video" class="video-area p-relative">
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="s-video-wrap videos_gallery">
                     <div class="s-video-content text-center">
                        <h6><a href="https://www.youtube.com/watch?v=lI7YzAFR0As" target='-blank' class="popup-video mb-50"><img src="../images/videos/play-button2.png" alt="Misty Moo Milk"/></a></h6>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</div>
)
}
export default Gallery
