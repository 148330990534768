import React from 'react'
import { Link } from 'react-router-dom'
function Franchise1() {
return (
<div>
   <div class="container-fluid padding-top padding-bottom banner-title" id='Services'>
      <div className='row align-items-center pt-4'>
         <div className='col-md-4 ps-0'>
            <img src="../images/videos/gallery-8.png" class="img-fluid franch" alt="Misty Moo Milk"/>
         </div>
         <div className='col-md-7'>
            <div className='row'>
               <div className='col-md-7 col-lg-7'>
                  <div className='banner-title ps-lg-5'>
                     <h2 className='head_h2'>Join the Misty Moo Franchise Family</h2>
                     <h4 className='head_h4'>– A Turnkey Dairy Delight Experience</h4>
                     <p className='section-ul'>
                     <ul className='section-wrapper'>
                        <li><i class="far fa-check-square"></i>Your Opportunity to Shine in the Dairy Industry</li>
                        <li><i class="far fa-check-square"></i>Initial Product Offering</li>
                        <li><i class="far fa-check-square"></i>Comprehensive Training</li>
                        <li><i class="far fa-check-square"></i>Continued Support and Development</li>
                        <li><i class="far fa-check-square"></i>Store Setup and Design</li>
                        <li><i class="far fa-check-square"></i>Branding and Marketing</li>
                        <li><i class="far fa-check-square"></i>Advanced Software and Technology</li>
                     </ul>
                     </p>
                     <p className='pt-lg-4'><Link class="btn delay4" to={"./Franchise"}>Franchise Opportunity</Link></p>
                  </div>
               </div>
               <div className='col-md-5 col-lg-5'>
                  <div className='banner-title'>
                     <img src="../images/video-3.png" class="img-fluid franch-2 mb-5" alt="Misty Moo Milk"/>
                     
                     <p>Step into the world of Misty Moo and let your taste buds revel in the simple, unadulterated joy of our dairy range. unadulterated joy of our dairy range.</p>
                     <p className='follow'>Follow Us:  <a href="https://www.instagram.com/mistymoomilkstore/" target="_blank"> <i class="fab fa-instagram pe-1"></i> @mistymoomilkstore</a></p>
                  </div>
               </div>
            </div>
         </div>
         <div className='col-md-1'></div>
      </div>
   </div>
</div>
)
}
export default Franchise1