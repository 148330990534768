import React from 'react'
import { Link } from 'react-router-dom'
function Product() {
const bgimages =
{
background: "#d9f6ff",
textalign: "center",
}
return (
<div>
   <div className="bg-image-3 padding-top padding-bottom banner-title">
      <div className='container'>
         <div className='row align-items-center'>
            <div className='col-lg-6'>
               <h2 className=" head_h2">Explore Our Delectable Dairy Range</h2>
               <h4 className='head_h4'>Nature's Goodness in Every Product</h4>
               <p>Dive into the heart of Misty Moo's dairy delights, where every product is a testament to nature's purity and richness. Our range is crafted to celebrate the authentic flavors of the farm, bringing you closer to the source with every sip and bite. </p>
               <p>From the creamy, indulgent texture of our freshly churned Ghee to the sweet, comforting notes of our traditional Rose and Badam Milks, each item in our collection is a symphony of taste.  Our Paneer, a staple for any cheese lover, is as versatile as it is delicious, perfect for adding a touch of gourmet to any dish.</p>
               <p>Don't miss our special Paalkova and Pista Milk, each offering a unique journey of flavors, and our variety of milkshakes, which are a creamy treat for any time of the day. At Misty Moo, we believe in the power of pure, wholesome ingredients. That's why our dairy products are free from unnecessary additives, ensuring that you're enjoying not just great taste, but also the full nutritional benefits of top-quality dairy. </p>
               <p>Step into the world of Misty Moo and let your taste buds revel in the simple, unadulterated joy of our dairy range. It's not just food; it's a celebration of nature's finest offerings.</p>
               <p className='pt-lg-4'><Link class="btn delay4" to={"./Franchise"}>Franchise Opportunity</Link> <a href="https://9d4236-4c.myshopify.com/products/cow-ghee-500-ml-organic-cow-ghee-misty-moo-milk-store" target="_blank" class="btn-2 delay4">Order Ghee Online</a></p>
            </div>
            <div className='col-lg-6'>
               <img src="../images/right-image.png" class="img-fluid" alt="Misty Moo Milk"/>
              
            </div>
         </div>
      </div>
   </div>
   <div className='container padding-top padding-bottom' id='Products'>
      <h2 class="text-center head_h2">Our Products</h2>
      <p className='mb-5 text-center'>Step into the world of Misty Moo and let your taste buds revel in the simple, unadulterated joy of our dairy range.<br/> It's not just food; it's a celebration of nature's finest offerings.</p>
      <div className='row'>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Ghee.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Cow Ghee</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Butter.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Butter</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Panner.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Paneer</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Palkova.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Paalkoha</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Milk.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Milk</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Curd.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Curd/Butter Milk</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Milkshake.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Milk Shakes</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/RoseMilk.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Rose Milk</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Badam Milk.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Badam Milk</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Butterscratch.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Butterscotch</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Strawberry ice cream.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Strawberry</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Oreo Kulfi.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Oreo Kulfi</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Chocolate ice cream.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Chocolate</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Badam Kulfi.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Badam Kulfi</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Mango Kulfi.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Mango Kulfi</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Pista Kulfi.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Pista Kulfi</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Chota Kulfi.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Chota Kulfi</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Punjabi Kulfi.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Punjabi Kulfi</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Vanilla ice cream.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Vanilla Ice Cream</h4>
            </div>
         </div>
         <div className='col-12 col-md-6 col-lg-3 mb-4'>
            <div className='lte-item'>
               <img src="../images/Nuts.png" class="img-fluid" alt="Misty Moo Milk"/>
               <h4 className='head_h4'>Nuts</h4>
            </div>
         </div>
      </div>
   </div>
   <div style={bgimages}>
      <img src="../images/image-19.png" class="img-fluid" alt="Misty Moo Milk Store"/>
   </div>
</div>
)
}
export default Product
