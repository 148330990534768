import "./App.css";
import NavMenu from "./Components/NavMenu";
import { Routes, Route, } from "react-router-dom";
import Home from "./Components/Home/Home";
import Franchise from "./Components/Franchise/Franchise";
import TermsPrivacyShipping from "./Components/TermsPrivacyShipping"
function App() {
return (
<div className="App">
   <NavMenu></NavMenu>
   <Routes>
      <Route path="/" element={<Home />}>
      <Route path="Home" element={<Home />} />
      <Route path="tasks" element={<Home />} /></Route>
      <Route path="franchise" element={<Franchise />} />
      <Route path="terms-privacy-shipping" element={<TermsPrivacyShipping />} />
   </Routes>
</div>
);
}
export default App;
