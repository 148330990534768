import React from 'react'
function InnerBanner() {
return (
<div id='AboutUs'>
   <div className='Banner-bg'>
      <div class="container py-lg-5">
         <div className='row align-items-center'>
            <div className='col-md-6 col-lg-6 mb-4'>
               <img src="../images/inner-1.svg" class="img-fluid" alt="Misty Moo Milk Store"/>
            </div>
            <div className='col-md-6 col-lg-6'>
               <img src="../images/inner-2.png" class="img-fluid" alt="Misty Moo Milk Store"/>
            </div>
         </div>
      </div>
   </div>
   <div className='padding-top '>
      <div className='container'>
         <div className='row'>
            <div className='col-md-4'>
               <img src="../images/moo-1.png" class="img-fluid" alt="Misty Moo Milk Store"/>
            </div>
            <div className='col-md-4'>
               <img src="../images/moo-4.png" class="img-fluid" alt="Misty Moo Milk Store"/>
            </div>
            <div className='col-md-4'>
               <img src="../images/moo-3.png" class="img-fluid" alt="Misty Moo Milk Store"/>
            </div>
         </div>
      </div>
   </div>
</div>
)
}
export default InnerBanner
