import React from 'react'
function Sponsors() {
return (
<div>
   <div className="padding-top padding-bottom">
      <div className='container'>
         <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 text-center'>
            <div className='col'>
               <img src="../images/01.png" class="img-fluid" alt="Misty Moo Milk"/> 
            </div>
            <div className='col'>
               <img src="../images/02.png" class="img-fluid" alt="Misty Moo Milk"/> 
            </div>
            <div className='col'>
               <img src="../images/03.png" class="img-fluid" alt="Misty Moo Milk"/> 
            </div>
            <div className='col'>
               <img src="../images/04.png" class="img-fluid" alt="Misty Moo Milk"/> 
            </div>
            <div className='col'>
               <img src="../images/05.png" class="img-fluid" alt="Misty Moo Milk"/> 
            </div>
         </div>
      </div>
   </div>
</div>
)
}
export default Sponsors
