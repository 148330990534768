import React from 'react'
function HappyReels() {
const myStyle = {
width: "245px", // Corrected: 'with' to 'width'
};
return (
<div id='Gallery'>
   <div className="padding-top padding-bottom">
      <div className='container'>
         <h2 className="text-center mb-5 head_h2">Misty Moo Happy Reels</h2>
         <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 text-center'>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-1.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-1.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-3.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-3.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-4.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-4.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-5.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-5.mp4" type="video/ogg"/>
               </video>
            </div>
            <div className='col'>
               <video style={myStyle} controls>
                  <source src="../images/videos/video-pop-1.mp4" type="video/mp4"/>
                  <source src="../images/videos/video-pop-1.mp4" type="video/ogg"/>
               </video>
            </div>
         </div>
      </div>
   </div>
</div>
)
}
export default HappyReels
