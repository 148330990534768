import React from 'react'
import InnerBanner from './InnerBanner'
import Footer from '../Footer'
import HappyReels from './HappyReels'
import Videos from './Videos'
import FranchisePage from './FranchisePage'
import Sponsors from '../Home/Sponsors'
function Franchise() {
return (
<div>
   <InnerBanner></InnerBanner>
   <HappyReels></HappyReels>
   <FranchisePage></FranchisePage>
   <Videos></Videos>
   <Sponsors></Sponsors>
   <Footer></Footer>
</div>
)
}
export default Franchise
