import React from 'react'
import Banner from './Banner'
import InstaVideos from '../InstaVideos'
import Product from './Product'
import Franchise1 from './Franchise1'
import Gallery from './Gallery'
import Sponsors from './Sponsors'
import Footer from '../Footer'
function Home() {
return (
<div>
   <Banner></Banner>
   <InstaVideos></InstaVideos>
   <Product></Product>
   <Franchise1></Franchise1>
   <Gallery></Gallery>
   <Sponsors></Sponsors>
   <Footer></Footer>
</div>
)
}
export default Home
