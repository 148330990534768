import React from 'react'
import Modal from 'react-bootstrap/Modal';
function Videos() {
const [modalShow, setModalShow] = React.useState(false);
const [modalSecShow, setModalSecShow] = React.useState(false); 
const [modalThreeShow, setModalThreeShow] = React.useState(false);
const [modalFourShow, setModalFourShow] = React.useState(false);
return (
<div id='Services'>
   <div className='padding-top '>
      <div className='container'>
         <div className='row'>
            <h2 className="text-center head_h2 mb-5">See What Our Customers Say</h2>
            <div className='col-md-6 mb-4'>
               <div class="about-right">
                  <div class="video-post text-center">
                     <div class="video-thumb">
                        <img src="../images/left-img.png" class="img-fluid" alt="Misty Moo Milk Store" />
                        <>
                        <MyVerticallyCenteredModalFour
                           show={modalFourShow}
                           onHide={() =>
                        setModalFourShow(false)}
                        />
                        </>
                        <a class="video-icon" variant="primary" onClick={() => setModalFourShow(true)}>
                        <i class="far fa-play-circle"></i>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="about-right">
                  <div class="video-post text-center">
                     <div class="video-thumb">
                        <img src="../images/video-4.png" class="img-fluid" alt="Misty Moo Milk Store" />
                        <>
                        <MyVerticallyCenteredModalThree
                           show={modalThreeShow}
                           onHide={() =>
                        setModalThreeShow(false)}
                        />
                        </>
                        <a class="video-icon" variant="primary" onClick={() => setModalThreeShow(true)}>
                        <i class="far fa-play-circle"></i>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="about-right">
                  <div class="video-post text-center">
                     <div class="video-thumb">
                        <img src="../images/video-2.png" class="img-fluid" alt="Misty Moo Milk Store" />
                        <>
                        <MyVerticallyCenteredModalSec
                           show={modalSecShow}
                           onHide={() =>
                        setModalSecShow(false)}
                        />
                        </>
                        <a class="video-icon" variant="primary" onClick={() => setModalSecShow(true)}>
                        <i class="far fa-play-circle"></i>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-md-6 mb-4'>
               <div class="about-right">
                  <div class="video-post text-center">
                     <div class="video-thumb">
                        <img src="../images/video-3.png" class="img-fluid" alt="Misty Moo Milk Store" />
                        <>
                        <MyVerticallyCenteredModal
                           show={modalShow}
                           onHide={() =>
                        setModalShow(false)}
                        />
                        </>
                        <a class="video-icon" variant="primary" onClick={() => setModalShow(true)}>
                        <i class="far fa-play-circle"></i>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className='container'>
      <div className='row'>
         <h2 className="text-center padding-top head_h2 mb-5">Misty Moo Google Reviews</h2>
         <div className='col-md-12'>
            <img src="../images/review.png" class="img-fluid" alt="Misty Moo Milk Store" />
         </div>
      </div>
   </div>
</div>
)
}
export default Videos
function MyVerticallyCenteredModal(props) {
const myStyle = {
width: "100%", // Corrected: 'with' to 'width'
height: "auto",
};
return (
<Modal
   {...props}
   size="md"
   aria-labelledby="contained-modal-title-vcenter" centered>
   <Modal.Header closeButton>
   </Modal.Header>
   <Modal.Body >
      <video style={myStyle} controls>
         <source src="../images/videos/video-1.mp4" type="video/mp4"/>
         <source src="../images/videos/video-1.mp4" type="video/ogg"/>
      </video>
   </Modal.Body>
</Modal>
);
}
function MyVerticallyCenteredModalSec(props) {
const myStyle = {
width: "100%", // Corrected: 'with' to 'width'
height: "auto",
};
return (
<Modal
   {...props}
   size="md" 
   aria-labelledby="contained-modal-title-vcenter" centered>
   <Modal.Header closeButton>
   </Modal.Header>
   <Modal.Body >
      <video style={myStyle} controls>
         <source src="../images/videos/video-5.mp4" type="video/mp4"/>
         <source src="../images/videos/video-5.mp4" type="video/ogg"/>
      </video>
   </Modal.Body>
</Modal>
);
}
function MyVerticallyCenteredModalThree(props) {
const myStyle = {
width: "100%", // Corrected: 'with' to 'width'
height: "auto",
};
return (
<Modal
   {...props}
   size="md"
   aria-labelledby="contained-modal-title-vcenter" centered>
   <Modal.Header closeButton>
   </Modal.Header>
   <Modal.Body >
      <video style={myStyle} controls>
         <source src="../images/videos/video-4.mp4" type="video/mp4"/>
         <source src="../images/videos/video-4.mp4" type="video/ogg"/>
      </video>
   </Modal.Body>
</Modal>
);
}
function MyVerticallyCenteredModalFour(props) {
const myStyle = {
width: "100%", // Corrected: 'with' to 'width'
height: "auto",
};
return (
<Modal
   {...props}
   size="md"
   aria-labelledby="contained-modal-title-vcenter" centered>
   <Modal.Header closeButton>
   </Modal.Header>
   <Modal.Body >
      <video style={myStyle} controls>
         <source src="../images/videos/video-2.mp4" type="video/mp4"/>
         <source src="../images/videos/video-2.mp4" type="video/ogg"/>
      </video>
   </Modal.Body>
</Modal>
);
}
