import React from 'react'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
function Banner() {
return (
<div >
   <div className='Banner-bg'>
      <div class="container">
         <div className='row align-items-center'>
            <div className='col-md-6 col-lg-6'>
               <div className='banner-title'>
                  <span>Discover the</span>
                  <h1>Pure Joy of Dairy <br/>with Misty Moo</h1>
                  <p className='pb-lg-4 text-white'>Experience Farm-Fresh goodness in every sip and bite.</p>
                  <p><a href="https://www.instagram.com/mistymoomilkstore/" target="_blank" class="btn delay4">Follow us on Instagram</a>
                  <Link class="btn-2 delay4" to={"./Franchise"}>Franchise Opportunity</Link>
                    
                  </p>
               </div>
            </div>
            <div className='col-md-6 col-lg-6'>
               <img src="../images/banner.png" class="img-fluid" alt="Misty Moo Milk"/>
            </div>
         </div>
      </div>
   </div>
   <div id='AboutUs' class="container padding-top padding-bottom">
      <div className='row align-items-center'>
         <div className='col-md-6 col-lg-6'>
            <div className='banner-title'>
               <h2 className='head_h2'>Welcome to Misty Moo </h2>
               <h4 className='head_h4 mb-3'> Where Dairy Dreams Come True!</h4>
               <p>At Misty Moo, we're dedicated to bringing you the freshest, purest, and most delicious dairy products. From our farm to your table, we ensure every product is crafted with care and quality, promising a taste of wholesome goodness in every bite.</p>
               <Tabs defaultActiveKey="Vision" id="fill-tab-example" className="my-4 tab-box" fill>
                  <Tab eventKey="Vision" title="Vision">
                     <p>To be the most loved dairy brand, renowned for its natural, farm-fresh products that bring families together, one delightful dairy experience at a time.</p>
                  </Tab>
                  <Tab eventKey="Mission" title="Mission">
                     <p>At Misty Moo, our mission is to provide consistently high-quality, nutritious, and flavorful dairy products. We strive to innovate while respecting traditional methods, ensuring that every product we offer contributes to the health and happiness of our customers. We are committed to sustainable practices, supporting local farmers, and being an integral part of the community.</p>
                  </Tab>
               </Tabs>
               <p className='pt-lg-4'><Link class="btn delay4" to={"./Franchise"}>Franchise Opportunity</Link></p>
            </div>
         </div>
         <div className='col-md-6 col-lg-6'>
            <img src="../images/image-top.png" class="img-fluid" alt="Misty Moo Milk"/>
         </div>
      </div>
   </div>
</div>
)
}
export default Banner
